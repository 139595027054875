<template>
    <div class="bar">
        <div class="container">
            <div class="bar-items">
                <router-link :to="{ name: 'Home'}">
                    <Home />
                </router-link>
                <!-- <Line /> -->
                <router-link :to="{ name: 'Serch' }">
                    <Serch />
                </router-link>
                
                <!-- <Line /> -->
                <router-link :to="{ name: 'Profile' }" v-if="store.user">
                    <User />
                </router-link>
                <router-link :to="{ name: 'Auth' }" v-else>
                    <User />
                 </router-link>
                <!-- <Line /> -->
                <router-link :to="{ name: 'History' }" v-if="store.user">
                    <History />
                </router-link>
                <router-link :to="{ name: 'Auth' }" v-else>
                    <History />
                </router-link>
                <!-- <Line /> -->
                <router-link :to="{ name: 'Basket' }">
                    <Basket />
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import Home from '@/components/Icon/Menu/Home'
import Serch from '@/components/Icon/Menu/Serch'
import User from '@/components/Icon/Menu/User'
import History from '@/components/Icon/Menu/History'
import Basket from '@/components/Icon/Menu/Basket'
// import Line from '@/components/Icon/Menu/Line'

import { store } from '@/store'

export default {
    name: "BottomBarApp",
    components: {
        Home,
        Serch,
        User,
        History,
        Basket,
        // Line,
    },
    data() {
        return {
            store
        }
    },
};
</script>
