<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
        <path d="M2.80005 8.79993H8.80005" stroke="#CCCCCC" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M1 7H7" stroke="#CCCCCC" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M16.6 3.3999L15.4 9.9999L4.84 11.6799C4.36 11.7399 4 12.1599 4 12.6399C4 13.1799 4.42 13.5999 4.96 13.5999H15.4" stroke="#CCCCCC" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M2.19995 1C3.57995 1 4.47995 2.44 3.87995 3.7L3.39995 4.6" stroke="#CCCCCC" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M1 1H2.2" stroke="#CCCCCC" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M3.3999 4.6001H15.9999" stroke="#CCCCCC" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M5.8001 16.0001C6.46284 16.0001 7.0001 15.4628 7.0001 14.8001C7.0001 14.1374 6.46284 13.6001 5.8001 13.6001C5.13736 13.6001 4.6001 14.1374 4.6001 14.8001C4.6001 15.4628 5.13736 16.0001 5.8001 16.0001Z" stroke="#CCCCCC" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M13.5999 16.0001C14.2626 16.0001 14.7999 15.4628 14.7999 14.8001C14.7999 14.1374 14.2626 13.6001 13.5999 13.6001C12.9372 13.6001 12.3999 14.1374 12.3999 14.8001C12.3999 15.4628 12.9372 16.0001 13.5999 16.0001Z" stroke="#CCCCCC" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
    name: "BasketIcon",
};
</script>