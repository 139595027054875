import { createRouter, createWebHistory } from "vue-router";

const routes = [
    {
        path: "/",
        name: "Home",
        component: () =>
            import(/* webpackChunkName: "profile" */ "../views/Home"),
    },
    {
        path: "/auth",
        name: "Auth",
        component: () =>
            import(/* webpackChunkName: "profile" */ "../views/Auth"),
    },
    {
        path: "/catalog/:id",
        name: "Catalog",
        component: () =>
            import(/* webpackChunkName: "profile" */ "../views/Catalog"),
    },
    {
        path: "/categories",
        name: "Categories",
        component: () =>
            import(/* webpackChunkName: "profile" */ "../views/Categories"),
    },
    {
        path: "/product/:id",
        name: "Product",
        component: () =>
            import(/* webpackChunkName: "profile" */ "../views/Product"),
    },
    {
        path: "/serch",
        name: "Serch",
        component: () =>
            import(/* webpackChunkName: "profile" */ "../views/Serch"),
    },
    {
        path: "/cart",
        name: "Basket",
        component: () =>
            import(/* webpackChunkName: "profile" */ "../views/Basket"),
    },
    {
        path: "/createorder",
        name: "CreateOrder",
        component: () =>
            import(/* webpackChunkName: "profile" */ "../views/CreateOrder"),
    },
    {
        path: "/thanks-order",
        name: "ThanksOrder",
        component: () =>
            import(/* webpackChunkName: "profile" */ "../views/ThanksOrder"),
    },
    {
        path: "/order",
        name: "Order",
        component: () =>
            import(/* webpackChunkName: "profile" */ "../views/Order"),
    },
    {
        path: "/profile/bonus",
        name: "Bonus",
        component: () =>
            import(/* webpackChunkName: "profile" */ "../views/Profile/Bonus"),
    },
    {
        path: "/profile",
        name: "Profile",
        component: () =>
            import(/* webpackChunkName: "profile" */ "../views/Profile/Profile"),
    },
    {
        path: "/edit",
        name: "Edit",
        component: () =>
            import(/* webpackChunkName: "profile" */ "../views/Profile/Edit"),
    },
    {
        path: "/history",
        name: "History",
        component: () =>
            import(/* webpackChunkName: "profile" */ "../views/Profile/Historys"),
    },
    {
        path: "/history/:id",
        name: "HistoryId",
        component: () =>
            import(/* webpackChunkName: "profile" */ "../views/Profile/History"),
    },
    {
        path: "/contact",
        name: "Contact",
        component: () =>
            import(/* webpackChunkName: "profile" */ "../views/Profile/Contacts"),
    },
    {
        path: "/about",
        name: "About",
        component: () =>
            import(/* webpackChunkName: "profile" */ "../views/About"),
    },
    {
        path: "/support",
        name: "Support",
        component: () =>
            import(/* webpackChunkName: "profile" */ "../views/Support")
    },
    {
        path: "/notifications",
        name: "Notifications",
        component: () =>
            import(/* webpackChunkName: "profile" */ "../views/Notifications")
    },
    {
        path: "/stock",
        name: "Stock",
        component: () =>
            import(/* webpackChunkName: "profile" */ "../views/Stock")
    },

]; 

// const router = createRouter({
//     history: createWebHistory(),
//     routes,
//     scrollBehavior() {

//         return { x: 0, y: 0 }
//     }
// });
const router = createRouter({
    history: createWebHistory(),
    scrollBehavior(to, from, savedPosition) {
        console.log(to, from, savedPosition)
        return { top: 0, behavior: 'smooth' } // always scroll to top
    },
    routes,
});

export default router;
