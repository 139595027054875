import axios from "axios";
import authHeader from "./auth-header";
const API_URL = "https://mobile.prodmarket.kz/api/";

class GetService {
    // HOME PAGE
    // getUser() {
    //     return axios.get(API_URL + `user/get_user_info`, {
    //         headers: authHeader(),
    //     });
    // }
    getUser() {
        return axios.get(API_URL + `user/get_user_info`, {
            headers: authHeader(),
        });
    }
    getOrders(user) {
        return axios.get(`https://prodmarket.kz/wp-json/wc/v3/orders/?customer=${user}&consumer_key=ck_008086239bbab2da45f775e9b0c6eb6dfca2a6d8&consumer_secret=cs_baf5055e0f92fe5d9a3ef676377f9561ad7d677f&page=1&per_page=100`);
    }

    
}

export default new GetService();
