<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_5539_5418)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7 3.5C7 3.22386 7.22386 3 7.5 3C7.77614 3 8 3.22386 8 3.5V4H16V3.5C16 3.22386 16.2239 3 16.5 3C16.7761 3 17 3.22386 17 3.5V4C18.1046 4 19 4.89543 19 6V17C19 18.1046 18.1046 19 17 19H7C5.89543 19 5 18.1046 5 17V6C5 4.89543 5.89543 4 7 4V3.5ZM11 7.75C10.8619 7.75 10.75 7.86193 10.75 8C10.75 8.13807 10.8619 8.25 11 8.25H17C17.1381 8.25 17.25 8.13807 17.25 8C17.25 7.86193 17.1381 7.75 17 7.75H11ZM10.75 12C10.75 11.8619 10.8619 11.75 11 11.75H17C17.1381 11.75 17.25 11.8619 17.25 12C17.25 12.1381 17.1381 12.25 17 12.25H11C10.8619 12.25 10.75 12.1381 10.75 12ZM11 15.75C10.8619 15.75 10.75 15.8619 10.75 16C10.75 16.1381 10.8619 16.25 11 16.25H17C17.1381 16.25 17.25 16.1381 17.25 16C17.25 15.8619 17.1381 15.75 17 15.75H11ZM10.1795 6.82596C10.2756 6.92508 10.2732 7.08336 10.174 7.17947L8.11154 9.17947C8.01456 9.27351 7.86044 9.27351 7.76346 9.17947L6.82596 8.27038C6.72684 8.17427 6.72441 8.01599 6.82052 7.91687C6.91664 7.81775 7.07492 7.81532 7.17404 7.91143L7.9375 8.65176L9.82596 6.82052C9.92509 6.72441 10.0834 6.72684 10.1795 6.82596ZM10.174 11.1795C10.2732 11.0834 10.2756 10.9251 10.1795 10.826C10.0834 10.7268 9.92509 10.7244 9.82596 10.8205L7.9375 12.6518L7.17404 11.9114C7.07492 11.8153 6.91664 11.8178 6.82052 11.9169C6.72441 12.016 6.72684 12.1743 6.82596 12.2704L7.76346 13.1795C7.86044 13.2735 8.01456 13.2735 8.11154 13.1795L10.174 11.1795ZM10.1795 14.826C10.2756 14.9251 10.2732 15.0834 10.174 15.1795L8.11154 17.1795C8.01456 17.2735 7.86044 17.2735 7.76346 17.1795L6.82596 16.2704C6.72684 16.1743 6.72441 16.016 6.82052 15.9169C6.91664 15.8178 7.07492 15.8153 7.17404 15.9114L7.9375 16.6518L9.82596 14.8205C9.92509 14.7244 10.0834 14.7268 10.1795 14.826Z" fill="#CCCCCC"/>
        </g>
        <defs>
            <clipPath id="clip0_5539_5418">
                <rect width="24" height="24" fill="white"/>
            </clipPath>
        </defs>
    </svg>


</template>

<script>
export default {
    name: "HistoryIcon",
};
</script>