import { createApp } from 'vue'
import router from "./router";
import "./assets/scss/main.scss";
import VueTheMask from 'vue-the-mask'


import App from './App.vue'

createApp(App)
    .use(router)
    .use(VueTheMask)
    .mount('#app')
