<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
        <path d="M6.33333 12.3334C9.27885 12.3334 11.6667 9.9456 11.6667 7.00008C11.6667 4.05456 9.27885 1.66675 6.33333 1.66675C3.38781 1.66675 1 4.05456 1 7.00008C1 9.9456 3.38781 12.3334 6.33333 12.3334Z" stroke="#545454" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10.3333 11.0001L15.6666 16.3334" stroke="#545454" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
    name: "SerchIcon",
};
</script>