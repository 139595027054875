import axios from "axios";

const API_URL = "https://prodmarket.kz/wp-json/wc/v3/";
const API_KEY = '?consumer_key=ck_008086239bbab2da45f775e9b0c6eb6dfca2a6d8&consumer_secret=cs_baf5055e0f92fe5d9a3ef676377f9561ad7d677f'

class GetService {
    // HOME PAGE
    getCategories(page) {
        return axios.get(API_URL + `products/categories${API_KEY}&page=${page}&per_page=100`);
    }
    getCategoryId(id) {
        return axios.get(API_URL + `products/categories/${id}${API_KEY}`);
    }
    getProductsCategory(id, page, filter) {
        let link = `products${API_KEY}&page=${page}&per_page=100&${filter}`
        if (!filter) { filter = '' }
        if (id == 'sell') {
            link = `${link}&on_sale=true`
        } else if (id == 'new') {
            link = `${link}&orderby=id&order=desc`
        } else {
            link = `${link}&category=${id}`
        }
        return axios.get(API_URL + link);
    }
    getProduct(id) {
        return axios.get(API_URL + `products/${id}/${API_KEY}`);
    }
    Serch(item) {
        return axios.get(`https://prodmarket.kz/?wc-ajax=dgwt_wcas_ajax_search&s=${item}`);
    }
    Support(data) {
        return axios.post(`https://prodmarket.kz/wp-json/contact-form-7/v1/contact-forms/20429/feedback`, data);
    }
}

export default new GetService();
