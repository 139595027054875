<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_3152_14117)">
            <path d="M13.3235 11.5967C11.7035 10.3966 10.1493 9.6101 9.79833 9.43776C9.75133 9.41583 9.72313 9.36883 9.72313 9.31869V7.88984C9.94247 7.51695 10.0835 7.12527 10.1367 6.76806C10.2965 6.75552 10.5034 6.53305 10.729 5.73715C11.036 4.64984 10.7102 4.49003 10.4344 4.5151C10.4877 4.36469 10.5284 4.21742 10.5535 4.06701C11.0235 1.25003 9.63539 1.1529 9.63539 1.1529C9.63539 1.1529 9.40665 0.71108 8.79875 0.3758C8.39454 0.134523 7.82738 -0.0503507 7.08475 0.0123185C6.84348 0.0248523 6.61473 0.0718542 6.40166 0.143924C6.12905 0.237928 5.87837 0.372666 5.64963 0.529339C5.37075 0.704813 5.10754 0.921022 4.87566 1.16856C4.50905 1.54458 4.18003 2.03027 4.03903 2.63502C3.91996 3.08624 3.94816 3.55939 4.0453 4.06701C4.0735 4.21429 4.1111 4.36469 4.16437 4.5151C3.88862 4.49003 3.55961 4.64984 3.86982 5.73715C4.09543 6.53305 4.30537 6.75552 4.46205 6.76806C4.51845 7.1284 4.65945 7.52635 4.87566 7.90237V9.31869C4.87566 9.36883 4.84746 9.41583 4.80046 9.43776C4.44951 9.6101 2.89532 10.3935 1.27532 11.5967C0.470019 12.1952 0 13.1478 0 14.1505V14.5359C0 14.9903 0.369748 15.36 0.8241 15.36H6.33899H8.25353H13.7684C14.2228 15.36 14.5925 14.9903 14.5925 14.5359V14.1505C14.6019 13.1446 14.1319 12.1952 13.3235 11.5967Z" fill="#CCCCCC"/>
        </g>
        <defs>
            <clipPath id="clip0_3152_14117">
                <rect width="15.36" height="15.36" fill="white"/>
            </clipPath>
        </defs>
    </svg>

</template>

<script>
export default {
    name: "UserIcon",
};
</script>