<template>
  <Header />
  <main>
    <img src="/image/louder.png" alt="" class="louder" v-if="louder">
    <router-view />
  </main>
  <BottomBar />
</template>

<script>
import mobile from '@/api/mobile';
import user from '@/api/user'

import Header from '@/components/Header'
import BottomBar from '@/components/BottomBar'

import { store } from '@/store'
// import api from '@/api/cart'
export default {
  name: 'App',
  components: {
    Header,
    BottomBar,

  },
  data() {
    return {
      store,
      louder: true,
    }
  },
  created() {
    mobile.getKey().then(res => {
      console.log(res.data)
    })
      setTimeout(() => this.isLouder(), 2000);
    // api.Carts()
  },
  mounted() {

    if (localStorage.getItem('cart')) {
      this.store.getCart()
    }
    if (localStorage.getItem('token')) {
      user.getUser().then(res => {
        // console.log(res)
        this.store.user = res.data
        this.GetOrders()
      }).catch(err => {
        console.log(err)
        // localStorage.removeItem('token')
      })
    }
    // if (localStorage.getItem('UserTime')) {
    //   this.store.user = JSON.parse(localStorage.getItem('UserTime'))
    // }
  },
  methods: {
    isLouder() {
      this.louder = false
    },
    GetOrders() {
      let orders = []
      let ordersId = []
      this.store.user.orders.forEach(e => {
        if (!ordersId.includes(e.order_id)) {
          // console.log(!ordersId.includes(e.order_id), ordersId, e.order_id)
          ordersId.push(e.order_id)
          orders.push(e)

        }
      });
      // console.log(orders)
      this.store.user.orders = orders
    }
  }
}
</script>

<style lang="scss" scoped>
.louder {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  // object-fit: cover;
}
</style>