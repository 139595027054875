import axios from "axios";

const API_URL = "https://mobile.prodmarket.kz/api/";

class GetService {
    // HOME PAGE
    getKey() {
        return axios.get(API_URL + `keys/get`);
    }
    getBanner() {
        return axios.get(API_URL + `content/banners`);
    }
    getStory() {
        return axios.get(API_URL + `content/stories`);
    }
    getBonusParams() {
        return axios.get(API_URL + `content/bonus`);
    }
    
}

export default new GetService();
