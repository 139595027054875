<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="28" viewBox="0 0 30 28" fill="none">
        <path d="M4.88013 14.5199H15.2801" stroke="white" stroke-width="1.7" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M1.76001 11.4H12.16" stroke="white" stroke-width="1.7" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M28.8 5.15994L26.72 16.5999L8.41596 19.5119C7.58396 19.6159 6.95996 20.3439 6.95996 21.1759C6.95996 22.1119 7.68796 22.8399 8.62396 22.8399H26.72" stroke="white" stroke-width="1.7" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M3.84009 1C6.23209 1 7.79209 3.496 6.75209 5.68L5.92009 7.24" stroke="white" stroke-width="1.7" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M1.76001 1H3.84001" stroke="white" stroke-width="1.7" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M5.91992 7.24005H27.7599" stroke="white" stroke-width="1.7" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10.08 27.0001C11.2288 27.0001 12.16 26.0688 12.16 24.9201C12.16 23.7713 11.2288 22.8401 10.08 22.8401C8.93125 22.8401 8 23.7713 8 24.9201C8 26.0688 8.93125 27.0001 10.08 27.0001Z" stroke="white" stroke-width="1.7" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M23.6 27.0001C24.7488 27.0001 25.68 26.0688 25.68 24.9201C25.68 23.7713 24.7488 22.8401 23.6 22.8401C22.4513 22.8401 21.52 23.7713 21.52 24.9201C21.52 26.0688 22.4513 27.0001 23.6 27.0001Z" stroke="white" stroke-width="1.7" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
    name: "SerchIcon",
};
</script>