<template>
    <header class="header">
        <div class="container">
            <div class="header-top">
                <router-link :to="{ name: 'Profile' }" class="header-top__menu">
                    <span></span>
                    <span></span>
                    <span></span>
                </router-link>
                <router-link :to="{ name: 'Home' }" class="header-top__logo">
                    <img src="@/assets/images/header/logo.png" alt="">
                </router-link>
                <div class="header-top__icon">
                    <router-link :to="{ name: 'Notifications' }">
                        <Notifications />
                    </router-link>
                    <router-link :to="{ name: 'Support' }">
                        <Supprort />
                    </router-link>
                </div>
            </div>
            <div class="header-bottom">
                <router-link :to="{ name: 'Serch' }" class="header-serch">
                    <label for="serch" class="header-serch__block">
                        <input type="text" placeholder="Искать..." id="serch" v-model="serch">
                        <Serch />

                    </label>
                </router-link>
                <router-link :to="{ name: 'Basket' }" class="header-basket">
                    <Basket />
                    <span>{{ store.cart.length }}</span>
                </router-link>
            </div>
        </div>
        
    </header>
</template>

<script>

import Notifications from '@/components/Icon/Header/Notifications'
import Supprort from '@/components/Icon/Header/Supprort'
import Serch from '@/components/Icon/Header/Serch'
import Basket from '@/components/Icon/Header/Basket'

import { store } from '@/store'
export default {
    name: "HeaderApp",
    components: {
        Notifications,
        Supprort,
        Serch,
        Basket,
    },
    watch: {
        serch() {
            this.$router.push(`/serch?serch=${this.serch}`)
        }
    },
    data() {
        return {
            serch: '',
            store,
        }
    }
};
</script>
