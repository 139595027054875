import { reactive } from 'vue'
import api from '@/api/get'
export const store = reactive({
    cart: [],
    cartId: [],
    newQuantity: false,
    user: '',
    total: 0,
    filterValue: [],
    product: [],
    orders: '',
    filter: {
        // visability: true,
        nextPage: false,
        category: 0,
        page: 1,
        orderby: '',
        min_price: '',
        max_price: '',
    },
    getCart() {
        this.cart = JSON.parse(localStorage.getItem('cart'))
        this.cartId = JSON.parse(localStorage.getItem('cartId'))
    },
    addToCart(data) {
        if (localStorage.getItem('cart')) {
            let e = JSON.parse(localStorage.getItem('cart'))
            let id = JSON.parse(localStorage.getItem('cartId'))
            if (id.includes(data.product_id)) {
                console.log(id)
            } else {
                e.push(data)
                id.push(data.product_id)
                localStorage.setItem('cart', JSON.stringify(e))
                localStorage.setItem('cartId', JSON.stringify(id))
            }
        } else {
            let e = []
            let id = []
            e.push(data)
            id.push(data.product_id)
            localStorage.setItem('cart', JSON.stringify(e))
            localStorage.setItem('cartId', JSON.stringify(id))
        }
    },
    GetTotal() {
        this.total = 0
        if (this.cart.length)
            this.cart.forEach(e => {
                this.total = this.total + (e.price * e.quantity)
            })
    },
    QuantityCart(metod, id, quantity) {

        console.log('quantity', quantity)
        if (metod == '+') {
            this.cart.forEach(e => {
                if (e.product_id == id) {
                    if (e.quantity < quantity) {
                        e.quantity++
                    }

                }
            })
            localStorage.setItem('cart', JSON.stringify(this.cart))
        }
        if (metod == '-') {
            let i = 0
            this.cart.forEach(e => {
                if (e.product_id == id) {
                    if (e.quantity > 1) {
                        e.quantity = e.quantity - 1
                    } else {
                        this.cart.splice(i, 1)
                        this.cartId.splice(i, 1)
                        localStorage.setItem('cart', JSON.stringify(this.cart))
                        localStorage.setItem('cartId', JSON.stringify(this.cartId))
                    }
                }
                i++
            })
            localStorage.setItem('cart', JSON.stringify(this.cart))
        }
        if (metod == 'rem') {
            let i = 0
            this.cart.forEach(e => {
                if (e.product_id == id) {
                    this.cart.splice(i, 1)
                    this.cartId.splice(i, 1)
                    localStorage.setItem('cart', JSON.stringify(this.cart))
                    localStorage.setItem('cartId', JSON.stringify(this.cartId))
                }
                i++
            })
            localStorage.setItem('cart', JSON.stringify(this.cart))
        }

        let total = 0
        if (this.cart.length)
            this.cart.forEach(e => {
                total = total + (e.price * e.quantity)
                this.total = total
            })
    },
    UpdateBasket() {
        this.cart.forEach(e => {
            api.getProduct(e.product_id).then(res => {
                console.log(res)
                if (e.quantity > res.data.stock_quantity) {
                    e.quantity = res.data.stock_quantity
                    this.newQuantity = true
                    localStorage.setItem('cart', JSON.stringify(this.cart))
                }
            })
        })
        // api.getProduct
    }
})